import React, { useState } from 'react';
import './link.css';
import { Row, Col } from "react-bootstrap";
import styled from 'styled-components'
import { YoutubeEmbed, GmapsEmbed } from './SpecialLinks';
import { DEFAULT_BUTTONS_TEXT_COLOR, DEFAULT_BUTTONS_COLOR, DEFAULT_BUTTONS_BODER_COLOR } from '../../DEFAULTS';
import Image from 'react-bootstrap/Image';
import { VscTriangleDown as ArrowDown, VscTriangleUp as ArrowUp} from 'react-icons/vsc';
import { TbSocial } from 'react-icons/tb';

export const getLink = (type, url) => {
  let res = url;
  if (type === "whatsapp") {
    res = `whatsapp://send?abid=${url}&text=www.tdsur.es`
  }
  if (type === "email") {
    res = `mailto:${url}`
  }
  if (type === "web" || type === "instagram" || type === "twitter" || type === "facebook") {
    res = res.includes("http") ? res : `http://${url}`;
  }
  return res;
}


export const StyledRowLink = styled.div`
  cursor: pointer;
  padding: 5px 5px;
  margin: 15px 15px;
  text-align: center;
  border-radius: 4em;
  font-size: 0.8em;
  line-height: 3em;
  color: ${(props) => props.settings?.buttonsTextColor || DEFAULT_BUTTONS_TEXT_COLOR};
  a,button {
    color: ${(props) => props.settings?.buttonsTextColor || DEFAULT_BUTTONS_TEXT_COLOR};
  }
  background-color: ${(props) => props.settings?.buttonsColor || DEFAULT_BUTTONS_COLOR};
  border: ${(props) => `solid 3px ${props.settings?.buttonsBorderColor || DEFAULT_BUTTONS_BODER_COLOR}`};
  :hover {
    filter: brightness(80%);
  }
`
export const LinkRow = ({url, name, description, settings, type, image, iconAsImage, onClick, icon}) => {
  switch (name) {
    // free links here
    case "youtube:video":
      return (
        <YoutubeEmbed embedId={url} description={description}/>
      )
    default:
      // fixed links here
      switch (type) {
        case "gmaps":
          return (
            <GmapsEmbed embedId={url} description={description}/>
          )
      default:
        return (
          <>
          { url ?
            <StyledRowLink className="rowLink" settings={settings} onClick={onClick}>
                <a href={`${ getLink(type, url)}`} target="_blank" rel="noreferrer"> 
                  <Row>
                    { iconAsImage ? iconAsImage : image &&
                      <Col xs={1} md={1} lg={1}>
                        <Image roundedCircle src={`${image}` } className="linkImage"/>
                      </Col>}
                    <Col>
                      <Row>
                        <Col className="linkName">
                        { name }
                        </Col>
                      </Row>
                      { description &&
                      <Row>
                        <Col className="linkDesc">
                          <div dangerouslySetInnerHTML={{ __html: description }} />
                        </Col>
                      </Row>
                      }
                    </Col>
                  </Row>
                </a>
               </StyledRowLink>
              : 
              <StyledRowLink className="rowLink" settings={settings} onClick={onClick}>
                { iconAsImage ? (<>
                      <Row>
                        <Col xs={1} md={1} lg={1} style={{ marginLeft: "5px" }}>
                          { iconAsImage }
                        </Col>
                        <Col className="linkName">
                          <span className="linkIcon"> { icon } </span>
                          <button > { name } </button> 
                        </Col>
                      </Row>
                </>) : <><span className="linkIcon"> { icon } </span> <button> { name } </button></>
                }
              </StyledRowLink>
              }
            </>
          );
    }
  }
};

export const getFreeLinks = (tenantInfo) => {
  let freeLinks = []
  for (let i = 1; i <= 10; i++) {
    let fl = tenantInfo[`link${i}`]

    fl && (fl.url || fl.pdf) && freeLinks.push(
      <LinkRow
        url={fl.url || fl.pdf}
        name={fl.name}
        description={fl.description}
        settings={tenantInfo.settings}
        image={fl.miniImage}
        type={"freeLink"}
      />
    )
  }
  return freeLinks;
}

export const getSocialLinks = (tenantInfo) => {
  let res = [];
  tenantInfo?.twitter && res.push(<LinkRow url={tenantInfo?.twitter?.url} name={tenantInfo?.twitter?.name} image={tenantInfo?.twitter?.miniImage} description={tenantInfo?.twitter?.description} settings={tenantInfo.settings} type="twitter"/>);
  tenantInfo?.facebook && res.push(<LinkRow url={tenantInfo?.facebook?.url} name={tenantInfo?.facebook?.name} image={tenantInfo?.facebook?.miniImage} description={tenantInfo?.facebook?.description} settings={tenantInfo.settings} type="facebook"/>);
  tenantInfo?.instagram && res.push(<LinkRow url={tenantInfo?.instagram?.url} name={tenantInfo?.instagram?.name} image={tenantInfo?.instagram?.miniImage} description={tenantInfo?.instagram?.description} settings={tenantInfo.settings} type="instagram"/>);
  return res;
}

export const getSection1Links = (tenantInfo) => {
  let res = [];
  tenantInfo?.website && tenantInfo.showWebsite && res.push(<LinkRow url={tenantInfo?.website?.url} name={tenantInfo?.website?.name} image={tenantInfo?.website?.miniImage} settings={tenantInfo.settings} type="web"/>);
  tenantInfo?.youtubeVideo && res.push(<LinkRow url={tenantInfo?.youtubeVideo?.url} name="youtube:video" settings={tenantInfo.settings} type="youtube"/>);
  // tenantInfo?.email && tenantInfo.showEmail && res.push(<LinkRow url={tenantInfo?.email} name="Email" image={tenantInfo?.email?.miniImage} settings={tenantInfo.settings} type="email"/>);
  // tenantInfo?.phone && tenantInfo.showWhatsapp && res.push(<LinkRow url={tenantInfo?.phone} name="Whatsapp" settings={tenantInfo.settings} image={tenantInfo?.phone?.miniImage} type="whatsapp"/>);
  return res;
}

export const getLastSectionLinks = (tenantInfo) => {
  let res = [];
  tenantInfo?.gmaps?.url && res.push(<LinkRow type="gmaps" settings={tenantInfo?.settings} url={tenantInfo?.gmaps?.url} description={tenantInfo?.gmaps?.description}/>);
  tenantInfo?.domain && res.push(<LinkRow url={`${process.env.REACT_APP_BACKEND_URL}/vcard/?domain=${tenantInfo?.domain}`} name="Agregar contacto" description="" settings={tenantInfo.settings} type="vCard"/>);
  return res;
}

export const LinkList = ({tenantInfo}) => {
  const [isActive, setIsActive] = useState(false);
  const socialLinks = getSocialLinks(tenantInfo);
  const freeLinks = getFreeLinks(tenantInfo);
  return (
    <Row className="links" fluid="md">
      <Col>
      </Col>
      <Col xs="12" md="12" lg="8">
        {getSection1Links(tenantInfo)}
        { socialLinks.length > 0 && (
            <LinkRow className="accordion-title" iconAsImage={<TbSocial size={"30px"}/>} name="Redes Sociales" settings={tenantInfo.settings} onClick={() => setIsActive(!isActive)} icon={isActive ? <ArrowUp/> : <ArrowDown/>} />)}
        { socialLinks.length > 0 && isActive && 
          <div className="accordion-content">
            {socialLinks}
          </div>}
        {freeLinks.length > 0 && freeLinks}
        {getLastSectionLinks(tenantInfo)}
      </Col>
      <Col>
      </Col>
    </Row>
  );
};
