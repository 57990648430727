import { useState, useEffect} from 'react';
import axios from 'axios'


// const BACKEND_URL = "http://64.225.83.34:8080"
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export const useAxiosGet = (path, params) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
      (async () => {
        try {
          const response = await axios.get(
            `${BACKEND_URL}/api/${path}`,
            { params }
          );
          setData(response.data);
        } catch (error) {
          console.error(error.message);
          setError(error.message);
        } finally {
          setLoaded(true);
        }
      })();
    }, []);
    return { data, error, loaded };
  };