import React from 'react';
import './SpecialLinks.css';
import './link.css';

export const YoutubeEmbed = ({embedId, description}) => {
    return (
      <>
        <div className="video-responsive">
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
            title="Embedded youtube"
          />
        </div>
        <div className="description" dangerouslySetInnerHTML={{__html: description}}/>
      </>
    );
  };

  export const GmapsEmbed = ({embedId, description}) => {
    return (
      <>
        <div className="map-responsive">
        <iframe
          src={embedId}
          width="600"
          height="450"
          style={{ border: "0"}}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Embedded gmaps"
          >
          </iframe>
        </div>
        <div className="description" dangerouslySetInnerHTML={{__html: description}}/>
      </>
    );
  };
  