import React from 'react';
import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from 'react-bootstrap/Carousel';

export const ImagesCarousel = ({tenantInfo}) => {
    let images = []
    for (let i = 1; i <= 4; i++) {
      let imgSrc = tenantInfo[`image${i}`]
  
      imgSrc && images.push(
        <Carousel.Item>
            <img
            className="d-block w-100"
            src={imgSrc}
            alt={`Imagen ${i}`} 
            />
        </Carousel.Item>
      )
    }
    return (
        <Carousel style={{ marginBottom: "20px" }}>
             {images}
        </Carousel>
    );
};
