import { DEFAULT_BACKGROUND_COLOR, DEFAULT_BUTTONS_COLOR } from "./DEFAULTS";

export const setHeaderInfo = async (tenantInfo) => {
    // Dynamically grab the current URL
    const currentURL = window.location.href;

    try {
        // Fetch and load the existing manifest.json file
        const response = await fetch('/manifest.json'); // Adjust the path as needed
        if (!response.ok) {
            throw new Error(`Failed to fetch manifest.json: ${response.status}`);
        }
        const existingManifest = await response.json();

        // Update specific parts of the manifest
        existingManifest.short_name = tenantInfo.name;
        existingManifest.start_url = currentURL;
        existingManifest.icons = [
            // Update your icons as needed
            {
                "src": tenantInfo?.favicon,
                "sizes": "64x64 32x32 24x24 16x16",
                "type": "image/x-icon"
            },
            {
                "src": tenantInfo?.appIcon,
                "type": "image/png",
                "sizes": "192x192"
            },
            {
                "src": tenantInfo?.appIcon,
                "type": "image/png",
                "sizes": "512x512"
            }
        ];
        existingManifest.theme_color = DEFAULT_BACKGROUND_COLOR;
        existingManifest.background_color = DEFAULT_BUTTONS_COLOR;


        // Convert the updated manifest back to a JSON string
        const updatedManifestString = JSON.stringify(existingManifest);

        // Create and append the updated manifest link element to the head
        const manifestLink = document.createElement('link');
        manifestLink.rel = 'manifest';
        manifestLink.href = `data:application/manifest+json;charset=utf-8,${encodeURIComponent(updatedManifestString)}`;
        document.head.appendChild(manifestLink);

        // Set the favicon dynamically
        const faviconLink = document.createElement('link');
        faviconLink.rel = 'icon';
        faviconLink.type = 'image/x-icon';
        faviconLink.href = tenantInfo?.favicon;
        document.head.appendChild(faviconLink);

    } catch (error) {
        console.error('Error loading or updating manifest.json:', error);
    }
};
