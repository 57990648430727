import './IndexPage.css';

export const IndexPage = () => {
  return (
    <>
      <nav>
        <h1>Tarjeta Digital Sur</h1>
      </nav>

      <header>
        <div id="left">
        <h2>Toda tu información. En un solo lugar.</h2>
        <h4>Comparte tu perfil, enlaces mediante un enlace o usando un código QR de forma instantánea</h4>
          <h3 className="button"><a className="noLink" href="https://tdsur.es/tdsuno"> Ver tarjeta de prueba </a></h3>
        </div>
        <img src="./movil.png" alt="Mobile mockup"/>
      </header>

      <section>
        <h3 className="top20">Empieza a compartir en segundos</h3>
        <p>Con nuestras tarjetas digitales podrá subir información sobre su tienda, PDFs, enlaces a sus redes sociales, videos interactivos y mucho más</p>
      </section>

      {/* <article>
        <h5>Qué obtienes</h5>
        <div>
          <img src="https://cdn1.iconfinder.com/data/icons/freeline/32/account_friend_human_man_member_person_profile_user_users-48.png"  className="icon"/>
          <h6>Fácil de usar<br/> e intuitivo</h6>
          <p className="top20">Fácil de usar. En dos pasos sencillos obtendrás tu tarjeta de contacto</p>  
        </div>
        <div>
          <img src="https://cdn1.iconfinder.com/data/icons/freeline/32/gps_location_map_marker-48.png" className="icon"/>
          <h6>Desde cualquier <br/>sitio</h6>
          <p className="top20">Podrás abrir tu perfil desde tu móvil, tablet o PC</p>
        </div>
        <div>
          <img src="./app_get_icon.png" className="icon"/>
          <h6>Sin necesidad <br/> de descargar nada</h6>
          <p className="top20">No necesitas descargar ninguna app, todo funciona desde el navegador</p>
        </div>
      </article> */}

      {/* <footer>
        <h4>Start Learning in Seconds</h4>
        <h3 className="button">Download on Google Play</h3>
        <div id="links"><div><h4>About</h4>
        <ul>
          <li>Company</li>
          <li>Company</li>
          <li>Company</li>
          </ul></div>  
        <div><h4>Legal</h4>
        <ul>
          <li>Company</li>
          <li>Company</li>
          <li>Company</li>
          </ul></div>
        <div><h4>Support</h4>
        <ul>
          <li>Company</li>
          <li>Company</li>    
          </ul></div>
        <div><h4>Social</h4>
        <ul>
          <li>Company</li>
          <li>Company</li>
          <li>Company</li>
          </ul></div></div>
      </footer> */}
    </>
  );
};
