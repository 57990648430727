import { Container } from "react-bootstrap";
import './ErrorPage.css';

export const ErrorPage = () => {
  return (
    <>
          <Container className="container">
          <div id="mainError">
            <div className="fof">
                  <h1>Error 404</h1>
                  <h2> Perfil no encontrado</h2>
            </div>
          </div>
            </Container>
    </>
  );
};
